import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("@/views/home/Home.vue"),
  },
  {
    path: "/start-sell-your-car",
    name: "Start",
    component: () => import("@/views/start/Start.vue"),
  },
  {
    path: "/FAQ-sell-your-car",
    name: "FAQ",
    component: () => import("@/views/FAQ/FAQ.vue"),
  },
  {
    path: "/blogs",
    name: "Blogs",
    component: () => import("@/views/blogs/blogs.vue"),
  },
  {
    path: "/how-to-sell-your-car-online",
    name: "Blogs",
    component: () => import("@/views/howToSellYourCarOnline/howToSellYourCarOnline.vue"),
  },
  {
    path: "/how-to-finance-a-car",
    name: "Blogs",
    component: () => import("@/views/howToFinanceACar/howToFinanceACar.vue"),
  },
  {
    path: "/how-to-sell-your-car-a-complete-guide",
    name: "Blogs",
    component: () => import("@/views/howToSellYourCarACompleteGuide/howToSellYourCarACompleteGuide.vue"),
  },
  {
    path: "/how-to-improve-the-market-value-of-your-car",
    name: "Blogs",
    component: () => import("@/views/bolg4/bolg4.vue"),
  },
  {
    path: "/ways-to-check-if-a-car-has-finance",
    name: "Blogs",
    component: () => import("@/views/bolg5/bolg5.vue"),
  },
  {
    path: "/about-sell-your-car",
    name: "About",
    component: () => import("@/views/about/About.vue"),
  },
  {
    path: "/location-sell-your-car",
    name: "Location",
    component: () => import("@/views/location/Location.vue"),
  },
  {
    path: "/sell-my-car-brisbane",
    name: "Location",
    component: () => import("@/views/sellMyCarBrisbane/sellMyCarBrisbane.vue"),
  },
  {
    path: "/sell-car-cash",
    name: "Location",
    component: () => import("@/views/sellCarCash/sellCarCash.vue"),
  },
  {
    path: "/sell-used-car",
    name: "Location",
    component: () => import("@/views/sellUsedCar/sellUsedCar.vue"),
  },
  {
    path: "/sell-car-online",
    name: "Location",
    component: () => import("@/views/sellMyCarOnline/sellMyCarOnline.vue"),
  },
  {
    path: "/Kone",
    name: "Kong",
    component: () => import("@/views/kong/Kong.vue"),
  },
];

const router = new VueRouter({
  routes,
});
//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push;
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
// 路由跳转后回到顶部
router.afterEach(() => {
  window.scrollTo(0, 0);
});
export default router;
